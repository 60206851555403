const isValidAddress = (address) => {
  return !!address.lat && !!address.lon;
};
const validateNeedPayment = (v) => {
  if (isNaN(parseFloat(v))) {
    return false;
  } else {
    if (parseFloat(v.toString().replace(",", ".")) > 0) {
      return true;
    } else {
      return false;
    }
  }
};
const validateMobile = (val) => {
  if (val) {
    if (
      /^[6]{1}[9]{1}[0-9]{8}$/.test(val.toString().trim()) ||
      /^[3]{1}[0]{1}[6]{1}[9]{1}[0-9]{8}$/.test(val.toString()) ||
      /^[2]{1}[1]{1}[0-9]{1}[0-9]{7}$/.test(val.toString()) ||
      /^[3]{1}[0]{1}[2]{1}[1]{1}[0-9]{1}[0-9]{7}$/.test(val.toString())
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export { isValidAddress, validateNeedPayment, validateMobile, validateEmail };
